import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Carousel } from 'react-responsive-carousel';

export default class DemoCarousel extends Component {
    render() {
        return (
            <Carousel width="50" axis="horizontal" dynamicHeight="50" emulateTouch="true" infiniteLoop="true"showStatus="false">
                <div>
                    <img src="img\type-a-safari.png" />
                   
                </div>
                <div>
                    <img src="img\type-b-safari.png" />
                    
                </div>
                <div>
                    <img src="img\type-c-safari.png" />
                  
                </div>
            </Carousel>
        );
    }
}