import React from 'react';

export default () => (
  <footer className='container-fluid black_more'>
  {/* <div className='bg-footer'>
    <img src='./img/footer-img.jpg' alt='bg-footer'/>
  </div> */}
  <div className='row'>
   <div className='col-2'></div>
    
    <div className='col-8 align-self-center text-center address'>
    <p>
    A1- 1st Floor Topra Plaza, SB-27/28 Block 13-C, Opp Urdu Science College, 
University Road, Gulshan e Iqbal, Karachi
    </p>
    <div className='text-center'>
        <div className='footer-col'>
          <div className='heading'>
            Follow Us
          </div>
          <div className='content'>
            
            <div className='socialicon'>
            <a href="https://www.facebook.com/MemonBuildersAndDevelopers">
            <span className="shine"></span>
              <i className="fa fa-facebook-f"></i></a>
            </div>
            {/* <div className='socialicon'>
            <span className="shine"></span>
              <i className="fa fa-linkedin"></i>
            </div> */}
            {/* <div className='socialicon'>
            <span className="shine"></span>
              <i className="fa fa-twitter"></i>
            </div> */}
            <div className='socialicon'>
            <span className="shine"></span>
              <i className="fa  fa-instagram"></i>
            </div>
          </div>
        </div>
      </div>
      <div className='link-call' onClick={()=> window.open("mailto:info@memonbuilders.pk", "_self")}>Email at. info@memonbuilders.pk</div>
      <div className='link-call' onClick={()=> window.open("tel:+923124830042", "_self")}>Call at. +92-312-4830042</div>
    </div>


    
</div>

   
    <div className='subfooter'>
      <div className='row m-10-hor'>
        <div className='col-md-6'>
          <div className='content'>© Copyrights 2021 <span className='font-weight-bold'><a href="#" className="heading-color" > ExpoBird </a></span>All rights reserved.</div>
        </div>
      </div>
    </div>
  </footer>
);
