import React from 'react';
import Slider from '../components/sliderhome';
import Carouselteam from '../components/carouselteam';
import Bannercontact from '../components/bannercontact';
import Pricing from '../components/pricing';
import Testimony from '../components/carouseltestimony';
import ModalVideo from '../components/modal-video';
import Footer from '../components/footer';
import { Link } from '@reach/router';

const NavLink = props => (
  <Link 
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);


export default () => (
  <div>
      <section className="jumbotron jumbomain">
          <Slider />
      </section>
      <section className='container-fluid black pb-0'>
        <div className='row m-10-hor mb-5'>

            <div className='col-12 text-center ' >
                  <div className='heading'>
                        ABOUT  <span className="heading-color">MEMON BUILDERS</span>
                  </div>
            </div>
        </div>

        <div className='row m-10-hor'>

          <div className='col-md-4'>
            <div className='heading'>
                WE TURN IDEAS <span className='br'></span> INTO ART 
            </div>
            <div className='content display-center' >
            Currently involved  Safari Enclave and Safari Signature, Memon Builders has built several other prestigious projects by bringing out the humanistic and engineering concept to the construction sphere and has had tremendous success with the approach
            </div>
          </div>

          <div className='col-md-3'>
            <div className='heading margin-top-mobile'>
                AMENITIES <span className='br'></span> 
            </div>
            <div className='row  display-center'>
              
                    <div className="col-4 mt-5">
                          <div>
                              <img src='./img/amenities 1.png' width="60"></img>
                          </div>
                    </div>
                    <div className="col-8 align-self-center  mt-5">
                      <p>03 Bedrooms</p>
                    </div>
              
                    <div className="col-4  mt-3">
                          <div>
                              <img src='./img/amenities 2.png' width="60"></img>
                          </div>
                    </div>
                    <div className="col-8 align-self-center  mt-3">
                      <p>04 Bathroom</p>
                    </div>
                    <div className="col-4  mt-3">
                          <div>
                              <img src='./img/amenities 3.png' width="60"></img>
                          </div>
                    </div>
                    <div className="col-8 align-self-center  mt-3">
                      <p>Lounge</p>
                    </div>
          </div>
          </div>

          <div className='col-md-5'>
                          <div className='mt-5 display-center '>
                              <img className='image-size'src='./img/room.png' ></img>
                          </div>
          </div>

        </div>




        </section>

        <section className='container-fluid black pb-0'>
        <div className='row m-10-hor mb-5'>

            <div className='col-12 text-center ' >
                  <div className='heading'>
                        OUR  <span className="heading-color">PROJECTS</span>
                  </div>
            </div>
        </div>

        <div className='row m-10-hor'>

              <div className="col-md-4">
                <div className="card" >
                      <img src="./img/project-3.jpg" className="card-img-top" alt="..."></img>
                      <div className="card-body">
                        <p className="heading-color">Coming soon</p>
                        <h5 className="card-title">Coming soon</h5>
                        <p className="heading-color">Area()</p>
                        {/* <p className="card-text">Bedroom <span className="br">Baths</span><span className="br">Free Parking</span></p> */}
                        <NavLink to="/contact"><span className="button-website">View Details</span></NavLink>
                      </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card margin-top-mobile" >
                      <img src="./img/project.jpg" className="card-img-top" alt="..."></img>
                      <div className="card-body">
                        <p className="heading-color">Duplex</p>
                        <h5 className="card-title">Safari Signature</h5>
                        <p className="heading-color">Area(2720 sq ft)</p>
                        {/* <p className="card-text">03 Bedroom <span className="br">04 Baths</span><span className="br">07 Rooms</span></p> */}
                        <NavLink to="/safari-signature"><span className="button-website">View Details</span></NavLink>
                      </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card margin-top-mobile" >
                      <img src="./img/project-2.jpg" className="card-img-top" alt="..."></img>
                      <div className="card-body">
                        <p className="heading-color">Majestic</p>
                        <h5 className="card-title">Safari Enclave</h5>
                        <p className="heading-color">Area(1050 sq ft)</p>
                        {/* <p className="card-text">02 Bedroom<span className="br">02 Baths</span><span className="br">04 Room</span></p> */}
                        <NavLink to="#"><span className="button-website">View Details</span></NavLink>
                      </div>
                </div>
              </div>



        </div>




        </section>
      
        <section className='container-fluid black pb-0'>
        <div className='row m-10-hor '>

            <div className='col-12 text-center ' >
                  <div className='heading'>
                        EXPLORE  <span className="heading-color">LIFESTYLE</span>
                  </div>
            </div>
            
        </div>
        <div className="text-center">
          <ModalVideo/> 
        </div>
            
        
      </section>
    <Footer /> 

  </div>
);
