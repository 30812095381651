import React, { Component } from 'react';
import { Link } from '@reach/router';
import Carouselteam from '../components/carouselteam';
import Bannercontact from '../components/bannercontact';
import Pricing from '../components/pricing';
import Testimony from '../components/carouseltestimony';
import Footer from '../components/footer';
import axios from 'axios';
import $ from "jquery";

const NavLink = props => (
  <Link 
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);
export default class  Latest extends Component{
  constructor(props) {
    super(props);
    this.state = {
        name : '',
        email : '',
        contact: '',
        message : '',
        marketing: '',
        PhoneNo: '',

    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
}

handleChange(event) {
    this.setState({[event.target.name] : event.target.value});

}
handleSubmit(event) {
    
    $('.thankyou-message').css({
        'display' : 'block'
        });
        setTimeout(()=>{
        $('.thankyou-message').css({
            'display' : 'none'
        });
    }, 2000);
    
    axios({
        method: 'post',
        url: 'https://expobird.com/email.php',
        headers: { 'content-type': 'application/json' },
        data: this.state
    })
    .then(result => {

        if(result.success){
            console.log('here')
        }
        
        /* this.setState({
            mailSent: result.data.sent
        }) */
    })
    .catch(error => this.setState({ error: error.message }));
    $('.services-cta').css('opacity', '0.5')
    $('.services-cta').attr('disabled', 'true')
    $('.services-cta').attr('disabled', 'true')
    event.preventDefault();
    
};

render(){
  return(
  <div>
            <div className="thankyou-message p-3">Thank you! We've received your message.</div>
      <section className='jumbotron breadcumb h-vh' style={{backgroundImage: `url(${'./img/banner-1.jpg'})`}}>
        <div className='homebread'>
          <div className='container-fluid'>
            <div className='row m-10-hor '>
              <div className='col-md-6 mr-auto '>
                <div>
                  <img className="safari-image-logo" src="./img/safari-signature-logo.png"></img>
                </div>
                <div className='heading'>
                            3 & 4 <span className="heading-color">Rooms</span><br/>
                            Spacious Apartments
                </div>
                <div>
                  <ul>
                <li>
                      Main Super Highway
                </li>
                <li>
                      Gated Community
                </li>
                <li>
                      Solar Powered Apartments
                </li>
                <li>
                      Two Towers Ready for Possession
                </li>

                  </ul>
                </div>
              </div>
              <div className='col-md-5 mr-auto '>
              <div className="card" >
              <div className='content'>Fill The Form Below To Schedule Your Site
                              <br/>Tour and Receive Project Details.
              </div>
              <div>
              <form className="formcontact" action="email.php" method="post" onSubmit={this.handleSubmit}> 
                
                <input type="text" placeholder="Your Name" name="name" value={this.state.value} onChange={this.handleChange} required />
                <input placeholder="Phone No." type="number" name="PhoneNo" value={this.state.value} onChange={this.handleChange} required />
                <input  type="email"  placeholder="Email" name="email" value={this.state.value} onChange={this.handleChange} required />
                <textarea placeholder="Message"  onChange={this.handleChange} cols="30" rows="3" className="cf-fields" name="message" required />
                <div id='success' className='hide'>Your message has been sent...</div>
                <div id='failed' className='hide'>Message failed...</div>
                <button type="submit" id='buttonsent'>
                  <span className="shine"></span>
                  <span>Send</span>
                </button>
              </form>
              </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container-fluid black_more'>
      <div className='row m-10-hor mb-5'>

          <div className='col-12 text-center ' >
                <div className='heading'>
                      OUR  <span className="heading-color">LOCATION</span>
                </div>
          </div>
          <div className='col-md-6 text-center mt-md-5 map-order' >
                <div>
                <iframe className="map-height-width"src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4301.840495162059!2d67.16231335111173!3d24.95242532766275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33872d6b6e95f%3A0xf7a9971669220d86!2sSafari%20Signature!5e0!3m2!1sen!2s!4v1633961669713!5m2!1sen!2s" width="600" height="450" allowfullscreen="" loading="lazy"></iframe>
                </div>
          </div>
          <div className='col-md-5 text-center mt-md-5 ' >
                <div>
                    <img className="location-image "src="./img/location.png" width="100"></img>
                </div>
                <br/>
                <br/>
                <div className='content' style={{textAlign:"left"}}>
                    Currently involved  Safari Enclave and Safari Signature, Memon Builders has built several other prestigious projects by bringing out the humanistic and engineering concept to the construction sphere and has had tremendous success with the approach
                </div>
          </div>
    </div>
    </section>
    

    <section className='container-fluid p-0 black'>
        <div className='row'>
          <div className='col-12 text-center mb-md-5' >
                <div className='heading'>
                      SAFARI  <span className="heading-color">SIGNATURE</span>
                </div>
          </div>
          <div className="col-md-1 pl-md-5 ">
            </div>
          <div className="col-md-5 pl-5 ">
                <div className='heading pt-md-5'>
                      Project Overview
                </div>
                <br/>
                <div className="content " style={{lineHeight:'45px'}}>
                Currently involved  Safari Enclave and Safari Signature, Memon Builders has built several other prestigious projects by bringing out the humanistic and engineering concept to the construction sphere and has had tremendous success with the approach
                </div>
                <br/>
                <div className="text-center">
                    <NavLink to="/contact"><span className="button-website mb-3">Book Now</span></NavLink>
                </div>
          </div>

          <div className="col-md-6 pl-md-4 text-right">
            <img src="./img/builing.png" className="margin-top-mobile" alt="#"/>
          </div>

        </div>
    </section>

    <section className='container-fluid black pb-0'>
        <div className='row m-10-hor mb-5'>

            <div className='col-12 text-center ' >
                  <div className='heading'>
                    PROPERTY  <span className="heading-color">DETAIL</span>
                  </div>
            </div>
        </div>

        <div className='row m-10-hor'>

              <div className="col-md-4">
                <div className="card" >
                      <img src="./img/safari-type-a.png" className="card-img-top" alt="..."></img>
                      <div className="card-body">
                        <h5 className="card-title">Type A</h5>
                        {/* <p className="heading-color">Area()</p> */}
                        <p className="card-text">Price 400,000<span className="br">Tv Lounge 1</span><span className="br">BathRoom 2</span><span className="br">BedRoom 2</span><span className="br">Drawing Room 1</span></p>
                        <NavLink to="/contact"><span className="button-website">View Details</span></NavLink>
                                              </div>
                </div>
              </div>
              <div className="col-md-4">
              <div className="card margin-top-mobile" >
                      <img src="./img/safari-type-a.png" className="card-img-top" alt="..."></img>
                      <div className="card-body">
                        <h5 className="card-title">Type B</h5>
                        {/* <p className="heading-color">Area()</p> */}
                        <p className="card-text">Price 500,000<span className="br">Tv Lounge 1</span><span className="br">BathRoom 4</span><span className="br">BedRoom 3</span><span className="br">Drawing Room 1</span></p>
                        <NavLink to="/contact"><span className="button-website">View Details</span></NavLink>
                                              </div>
                </div>
              </div>
              <div className="col-md-4">
              <div className="card margin-top-mobile" >
                      <img src="./img/safari-type-a.png" className="card-img-top" alt="..."></img>
                      <div className="card-body">
                        <h5 className="card-title">Type C</h5>
                        {/* <p className="heading-color">Area()</p> */}
                        <p className="card-text">Price 1,000,000<span className="br">Tv Lounge 2</span><span className="br">BathRoom 5</span><span className="br">BedRoom 4</span><span className="br">Drawing Room 1</span></p>
                        <NavLink to="/contact"><span className="button-website">View Details</span></NavLink>
                                              </div>
                </div>
              </div>



        </div>




        </section>
    <Footer />

  </div>
);
  }
}
