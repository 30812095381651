import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import ModalVideo from 'react-modal-video';
import {AiOutlinePlayCircle} from 'react-icons/ai';
import { pointer } from 'popmotion';


export default class Banner extends Component{
    
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
      }
     
      openModal () {
        this.setState({isOpen: true})
      }
      render(){
        return(
                <div>
                    <div className="col-12">
                        <ModalVideo 
                        channel='youtube' 
                        isOpen={this.state.isOpen} 
                        videoId='rAEcJe-zh2k'    
                        onClose={() => this.setState({isOpen: false})} />


                        <div > 
                            <div className="play-btn-inside"><AiOutlinePlayCircle/>
                            </div>
                            <div>
                                <img  className="image-size-video" style={{opacity:'0.3', cursor:'pointer'}} onClick={this.openModal} src="./img/safari-building.png"></img>
                            </div>
                        </div>
                    </div>
                </div>
        );
        
    }
    }

