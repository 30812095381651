import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import { Link } from '@reach/router';
import Footer from '../components/footer';
import axios from 'axios';
import $ from "jquery";

const NavLink = props => (
  <Link 
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);

export default class  OurProject extends Component{
  constructor(props) {
    super(props);
    this.state = {
        name : '',
        email : '',
        contact: '',
        message : '',
        marketing: '',
        PhoneNo: '',

    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
}

handleChange(event) {
    this.setState({[event.target.name] : event.target.value});

}
handleSubmit(event) {
    
    $('.thankyou-message').css({
        'display' : 'block'
        });
        setTimeout(()=>{
        $('.thankyou-message').css({
            'display' : 'none'
        });
    }, 2000);
    
    axios({
        method: 'post',
        url: 'https://expobird.com/email.php',
        headers: { 'content-type': 'application/json' },
        data: this.state
    })
    .then(result => {

        if(result.success){
            console.log('here')
        }
        
        /* this.setState({
            mailSent: result.data.sent
        }) */
    })
    .catch(error => this.setState({ error: error.message }));
    $('.services-cta').css('opacity', '0.5')
    $('.services-cta').attr('disabled', 'true')
    $('.services-cta').attr('disabled', 'true')
    event.preventDefault();
    
};

render(){
  return(
    <div>
                    <div className="thankyou-message p-3">Thank you! We've received your message.</div>

    <section className='jumbotron breadcumb' style={{backgroundImage: `url(${'./img/banner-1.jpg'})`}}>
        <div className='mainbreadcumb'>
          <div className='container-fluid'>
            <div className='row m-10-hor'>
              <div className='col-md-6'>
                <h1><span className="heading-color">OUR PROJECTS</span></h1>
              </div>
            </div>
          </div>
        </div>
      </section>

        <section className='container-fluid black pb-0'>
        <div className='row m-10-hor mb-5'>

            <div className='col-12 text-center ' >
                  <div className='heading'>
                  Our <span className="heading-color">Projects</span>
                  </div>
            </div>
            <div className='col-2' ></div>
            
            <div className='col-8 text-center pl-md-5 align-self-center' >
              <div className="content" style={{lineHeight:'45px'}}>
              With Safari Enclave and Safari Signature, Memon Builders has built several other prestigious projects by bringing out the humanistic and engineering concept to the construction sphere and has had tremendous success with the approach.

              </div>
            </div>
            <div className='col-2' ></div>
        </div>
        </section>
        <section className='container-fluid black pb-0'>
        <div className='row m-10-hor mb-5'>

            <div className='col-12 text-center ' >
                  <div className='heading'>
                  Our <span className="heading-color">Track Record</span>
                  </div>
            </div>
            <div className='col-2' ></div>
            
            <div className='col-8 text-center pl-md-5 align-self-center' >
              <div className="" style={{lineHeight:'45px'}}>
              <ul style={{textAlign:'left'}}> 
                <li>Safari View Appartments</li>
                <li>Safari Avenue</li>
                <li>Safari Heights</li>
                <li>Safari Blessing</li>
                <li>Safari Comforts</li>
                <li>Safari Boulevard I</li>
                <li>Safari Boulevard II</li>
                <li>Safari Boulevard III</li>
                <li>Safari Sunlay Cottages</li>
                <li>Safari Duplex</li>
                <li>Safari Omega View</li>
                <li>Rabbiya Garden</li>
                <li>Safari Villas Ajmaan (U.A.E)</li>
                <li>Safari Palm Village</li>
                <li>
                  <NavLink to="/safari-enclave"><span className="heading-color">Safari Enclave</span></NavLink></li>
                <li>                  
                  <NavLink to="/safari-signature"><span className="heading-color">Safari Signature</span></NavLink></li>

              </ul>

              </div>
            </div>
            <div className='col-2' ></div>
        </div>
        </section>


      <section className='container-fluid black_more'>
        <div className='row m-10-hor'>
          <div className='col-md-6'>
           <div className="text-side">
             <h2>Get in Touch</h2>
              <div className='address'>
                <div className='heading'>Our Office</div>
                <div className='list'>
                  <i className="fa fa-map-marker"></i>
                  <a href='https://goo.gl/maps/kiKcUAAZjY69AghKA' target='_blank' rel='noopener noreferrer'>
                    A1- 1st Floor Topra Plaza, SB-27/28 Block 13-C, Opp Urdu Science College, University Road, Gulshan e Iqbal, Karachi
                    
                  </a>
                </div>
                <div className='list'>
                  <i className="fa fa-envelope-o"></i>
                  <a href='mailto:info@memonbuilders.com' target='_blank' rel='noopener noreferrer'>
                    info@memonbuilders.com
                  </a>
                </div>
                <div className='list'>
                  <i className="fa fa-phone"></i>
                  <a href='tel:+923124830042' target='_blank' rel='noopener noreferrer'>
                      +92-312-4830042
                  </a>
                </div>
              </div>
           </div>
          </div>
          <div className='col-md-6'>
          <div className="card" >
              <div className='content'>Fill The Form Below To Schedule Your Site
                              <br/>Tour and Receive Project Details.
              </div>
              <div>
              <form className="formcontact" action="email.php" method="post" onSubmit={this.handleSubmit}> 
                
                <input type="text" placeholder="Your Name" name="name" value={this.state.value} onChange={this.handleChange} required />
                <input placeholder="Phone No." type="number" name="PhoneNo" value={this.state.value} onChange={this.handleChange} required />
                <input  type="email"  placeholder="Email" name="email" value={this.state.value} onChange={this.handleChange} required />
                <textarea placeholder="Message"  onChange={this.handleChange} cols="30" rows="3" className="cf-fields" name="message" required />
                <div id='success' className='hide'>Your   message has been sent...</div>
                <div id='failed' className='hide'>Message failed...</div>
                <button type="submit" id='buttonsent'>
                  <span className="shine"></span>
                  <span>Send</span>
                </button>
              </form>
              </div>
              </div>
          </div>
        </div>
      </section>
      <div className="container text-center">
            <iframe className="map-height-width" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2151.574208487024!2d67.08914458204808!3d24.914960390971462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33f2df6c803ff%3A0x783bba644a13639a!2sMemon%20Builders!5e0!3m2!1sen!2s!4v1633965842102!5m2!1sen!2s" width="800" height="450" style={{borderRadius:10}} ></iframe>
      </div>
      <Footer />
    </div>
  );
}
}
