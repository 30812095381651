import React from 'react';
import emailjs from 'emailjs-com';
import { Link } from '@reach/router';
import Footer from '../components/footer';
import Carousel from '../components/carouselteam'

export default function() {

  return (
    <div>
    <section className='jumbotron breadcumb' style={{backgroundImage: `url(${'./img/banner-1.jpg'})`}}>
        <div className='mainbreadcumb'>
          <div className='container-fluid'>
            <div className='row m-10-hor'>
              <div className='col-md-6'>
                <h1><span className="heading-color">Safari Signature</span></h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='container-fluid black pb-0'>
        <div className='row m-10-hor mb-5'>

            <div className='col-12 text-center ' >
                  <div className='heading'>
                  Our <span className="heading-color">Features</span>
                  </div>
            </div>
            <div>
              <Carousel/>
            </div>
            
        </div>
        </section>
        
      <Footer />
    </div>
  );
}
