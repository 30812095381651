import React from 'react';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/vertical.css';
import { Link } from '@reach/router';

const content = [
  {
    title: "WORLD CLASS LUXURY LIVING IN KARACHI",
    description:
      "MEMON builders & developers is an undertaking of a larger body MEMON GROUP. It is a construction company that has built prestigious projects throughout the course of over 35 years as most trusted and preferred real estate developer across Pakistan, since 1979.",
    button: "Book Now",
    link: "/contact",
    image: "./img/banner-1.jpg"
  },
];
const NavLink = props => (
  <Link 
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);

export default () => (
  <Slider className="slider-wrapper" autoplay={6000}>
            {content.map((item, index) => (
              <div
                key={index}
                className="slider-content"
                style={{ background: `url('${item.image}') no-repeat center center` }}
              >
                <div className="inner text-center mt-md-5">
                  <h1>{item.title}</h1>
                  <p >{item.description}</p>
                  <button>
                    <NavLink to="/contact">
                      <span className="shine"></span>
                    <span>
                      {item.button}
                    </span>
                    </NavLink>
                  </button>
                </div>
              </div>
            ))}
        </Slider>
);
